export default function isInAppBrowser() {
    const userAgent = navigator.userAgent || window.opera;
    // List of common in-app browser user agent patterns
    const inAppBrowserPatterns = [
      'FBAN', // Facebook in-app browser
      'FBAV', // Facebook in-app browser
      'Instagram', // Instagram in-app browser
      'Twitter', // Twitter in-app browser
      'Line', // Line in-app browser
      'Snapchat', // Snapchat in-app browser
      'WhatsApp', // WhatsApp in-app browser
      'Messenger', // Facebook Messenger in-app browser
      'WeChat', // WeChat in-app browser
      'QQ', // QQ in-app browser
      'TikTok', // TikTok in-app browser
    ];
    return inAppBrowserPatterns.some(pattern => userAgent.includes(pattern));
}