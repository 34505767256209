import React from "react";
import { createRoot } from "react-dom/client";
import WizardForm from "./WizardForm";
import * as Sentry from "@sentry/react";
import './App.css';
// Stripe
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import {
    stripeLivePublishableKey,
    stripeTestPublishableKey
  } from './utils/constants';

if(process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: "https://3be8868d19b5477ab89ad159bfa75f23@o805225.ingest.sentry.io/6267111",
        integrations: [Sentry.browserTracingIntegration()],
        tracesSampleRate: 0.15,
    });
}

const stripePromise = loadStripe(process.env.NODE_ENV === "production" ? stripeLivePublishableKey : stripeTestPublishableKey);

const App = () => (
    <Elements stripe={stripePromise}>
        <WizardForm/>
    </Elements>
)

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App/>);