import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

import { FiChevronLeft  } from 'react-icons/fi';
import { FaFacebookF } from 'react-icons/fa';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import InputAdornment from "@mui/material/InputAdornment";
import CustomInput from "./materialComponents/CustomInput/CustomInput.js";
import Button from "./materialComponents/CustomButtons/Button.js";
import Alert from '@mui/material/Alert';

import { getApp } from "firebase/app";
import remoteApi from '../api/remoteApi';
import {
  getAuth,
  setPersistence,
  inMemoryPersistence,
  FacebookAuthProvider,
  GoogleAuthProvider,
  signOut,
  signInWithRedirect,
  signInWithPopup,
  signInWithEmailAndPassword
} from 'firebase/auth';

import {
  isIOS,
  isSafari,
} from 'react-device-detect';

import isInAppBrowser from '../utils/inAppBrowser';

class SignInForm extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      passwordVisible: false,
      manualEmail: '',
      manualPassword: '',
      manualEmailValid: true,
      manualPasswordValid: true,
      signInError: null,
      signInLoading: false,
      inAppBrowser: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.firebaseApp = getApp();
  }

  componentDidMount() {
    // Check if the user is using an in-app browser
    if(isInAppBrowser()) {
      this.setState({ inAppBrowser: true });
    }
    // Check if there is a social redirect error
    if(this.props.socialRedirectError && this.props.socialRedirectError === true) {
      this.setState({signInError: 'Oops. Looks like this an unexpected error occured during the sign-in process.'});
    }
  }

  async submitManualLogin() {
    let emailValid, passwordValid;
    if(this.validateEmail(this.state.manualEmail)){
      emailValid = true;
    } else {
      emailValid = false;
    }
    if(this.state.manualPassword.length > 0){
      passwordValid = true;
    } else {
      passwordValid = false;
    }
    this.setState({manualEmailValid: emailValid, manualPasswordValid: passwordValid});
    if(emailValid && passwordValid){
      try {
        this.setState({signInError: null, signInLoading: true});
        const auth = getAuth(this.firebaseApp);
        await setPersistence(auth, inMemoryPersistence);
        let authResponse = await signInWithEmailAndPassword(auth, this.state.manualEmail, this.state.manualPassword);
        let idToken = await authResponse.user.getIdToken();
        // Session login endpoint is queried and the session cookie is set
        await remoteApi.signIn({ idToken: idToken, csrfToken: null });
        await signOut(auth);
        this.setState({signInLoading: false});
        this.props.nextPage();
      } catch(error) {
        let errorCode = error.code;
        console.log(error);
        if(errorCode === 'auth/invalid-email'){
          this.setState({signInError: 'Invalid email address'});
        } else if(errorCode === 'auth/user-disabled'){
          this.setState({signInError: 'Account disabled'});
        } else if(errorCode === 'auth/user-not-found'){
          this.setState({signInError: 'Account does not exist'});
        } else if(errorCode === 'auth/wrong-password'){
          this.setState({signInError: 'Incorrect password'});
        } else if(error.message.includes("TOO_MANY_ATTEMPTS_TRY_LATER")){
          this.setState({signInError: 'Too many unsuccessful login attempts. Please try again later.'});
        } else if(error.message == 'Email not verified'){
          this.setState({signInError: 'Please verify your email address using the verification email which we sent you.'});
        } else {
          this.setState({signInError: 'Sorry, an unexpected error has occured'});
        }
        this.setState({signInLoading: false});
      }
    } else {
      return;
    }
  }

  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  handleChange(event) {
    event.persist();
    if(event.target.id == 'manualEmail'){            
      this.setState((state) => state.manualEmail = event.target.value);
    } else if(event.target.id == 'manualPassword'){
      this.setState((state) => state.manualPassword = event.target.value);
    }
  }

  async submitFacebookLogin() {
    try {
      // Conditionally sign-in with popup (see signInWithRedirect advisory)
      if(this.props.embedded === true || (isIOS || isSafari)) { 
        await this.submitLoginPopup('facebook');
        return;
      }
      this.setState({signInError: null, signInLoading: true});
      localStorage.setItem('widget_page', this.props.page);
      localStorage.setItem('widget_booking_data', JSON.stringify(this.props.booking_data));
      if(this.props.time) {
        localStorage.setItem('widget_time', this.props.time);
      }
      if(this.props.date) {
        localStorage.setItem('widget_date', this.props.date.format('YYYY-MM-DD'));
      }
      const auth = getAuth(this.firebaseApp);
      const provider = new FacebookAuthProvider();
      await signInWithRedirect(auth, provider);
    } catch(e) {
      console.log(e);
      this.setState({signInError: 'Sorry, an unexpected error has occurred', signInLoading: false});
    }
  }

  async submitGoogleLogin() {
    try {
      // Conditionally sign-in with popup (see signInWithRedirect advisory)
      if(this.props.embedded === true || (isIOS || isSafari)) { 
        await this.submitLoginPopup('google');
        return;
      }
      this.setState({signInError: null, signInLoading: true});
      localStorage.setItem('widget_page', this.props.page);
      localStorage.setItem('widget_booking_data', JSON.stringify(this.props.booking_data));
      if(this.props.time) {
        localStorage.setItem('widget_time', this.props.time);
      }
      if(this.props.date) {
        localStorage.setItem('widget_date', this.props.date.format('YYYY-MM-DD'));
      }
      const auth = getAuth(this.firebaseApp);
      const provider = new GoogleAuthProvider();
      await signInWithRedirect(auth, provider);
    } catch(e) {
      this.setState({signInError: 'Sorry, an unexpected error has occurred', signInLoading: false});
    }
  }

  async submitLoginPopup(providerName) {
    try {
      this.setState({signInError: null, signInLoading: true});
      const auth = getAuth(this.firebaseApp);
      let provider;
      if(providerName === 'facebook') {
        provider = new FacebookAuthProvider();
      } else if(providerName === 'google') {
        provider = new GoogleAuthProvider();
      }
      await setPersistence(auth, inMemoryPersistence);
      const authResponse = await signInWithPopup(auth, provider);
      if(providerName === 'facebook') {
        await FacebookAuthProvider.credentialFromResult(authResponse);
      } else if(providerName === 'google') {
        await GoogleAuthProvider.credentialFromResult(authResponse);
      }
      let tokenResponse = authResponse._tokenResponse;
      await remoteApi.socialSignIn({
        idToken: tokenResponse.idToken,
        csrfToken: null,
        firstname: tokenResponse.firstName,
        lastname: tokenResponse.lastName,
        email: tokenResponse.email,
        business_id: this.props.business_id
      });
      await signOut(auth);
      this.setState({signInLoading: false});
      this.props.nextPage();
    } catch(e) {
      let errorCode = e.code;
      if(errorCode === 'auth/user-disabled'){
        this.setState({signInError: 'Account disabled'});
      } else if(errorCode === 'auth/popup-blocked') {
        this.setState({signInError: 'Sign-in popup blocked by browser. Please click the sign-in button again to retry.'});
      } else if(e.message.includes("TOO_MANY_ATTEMPTS_TRY_LATER")){
        this.setState({signInError: 'Too many unsuccessful login attempts. Please try again later.'});
      } else if(errorCode === "auth/popup-closed-by-user") {
        console.log('Popup closed by user');
      } else {
        this.setState({signInError: 'Sorry, an unexpected error has occurred'});
      }
      this.setState({signInLoading: false});
    }
  }

  render() {
    return (
      <div className="left">
        {this.state.signInLoading ?
          <div className="loading-container">
            <div className="loading-container-spinner">
              <CircularProgress size={45} color={'secondary'}/>
            </div>
          </div>
        : null}
        <div
          className="page-title"
          style={this.props.backNavigation ? null : {paddingLeft: 25}}
        >
          <div>
            {this.props.backNavigation ?
              <button
                className="back-button"
                onClick={() => this.props.changeScreen()}
              >
                <FiChevronLeft size={20}/>
              </button>
            : null}
            Sign in
          </div>
        </div>
        <div className="sign-in-container">
          <div style={{ marginBottom: '1em' }}>
            <img
              className="ws-sign-in-logo"
              src="/logo/styler_logo_dark.svg"
              height="55px"
            />
          </div>
          <CustomInput
            labelText="Email"
            id="manualEmail"
            error={!this.state.manualEmailValid}
            formControlProps={{
              fullWidth: true
            }}
            className="sign-in-input"
            inputProps={{
              value: this.state.manualEmail,
              onChange: this.handleChange
            }}
          />
          <CustomInput
            labelText="Password"
            id="manualPassword"
            error={!this.state.manualPasswordValid}
            formControlProps={{
              fullWidth: true
            }}
            inputProps={{
              type: this.state.passwordVisible ? "text" : "password",
              endAdornment: (
                <InputAdornment position="end">
                  {this.state.passwordVisible ? <FiEyeOff size={20} color={'#000000'} onClick={() => { this.setState({passwordVisible: !this.state.passwordVisible}) }} style={{cursor: 'pointer'}} /> : <FiEye size={20} color={'#000000'} onClick={() => { this.setState({passwordVisible: !this.state.passwordVisible}) }} style={{cursor: 'pointer'}} />}
                </InputAdornment>
              ),
              autoComplete: "off",
              value: this.state.manualPassword,
              onChange: this.handleChange
            }}
          />
          <div
            style={{color: '#AAAAAA', fontFamily: 'Poppins', marginBottom: 10, fontSize: 11, cursor: 'pointer'}}
            onClick={() => this.props.forgotPassword()}
          >
            Forgot password
          </div>
          <Button
            fullWidth
            className="sign-in-button"
            color="black"
            onClick={() => this.submitManualLogin()}
          >
            Sign in
          </Button>
          {this.state.signInError ?
            <div className="sign-in-error-container">
              <div className="sign-in-error-label">
                {this.state.signInError}
              </div>
            </div>
          : null}
          <div className="sign-in-separator">
            <div style={{flex: 0.4, verticalAlign: 'middle', alignSelf: 'center'}}>
              <div style={{ border: '1px solid #d2d2d2' }}/>
            </div>
            <div style={{textAlign: 'center', display: 'inline-block', flex: 0.2, fontFamily: 'Poppins'}}>or</div>
            <div style={{flex: 0.4, verticalAlign: 'middle', alignSelf: 'center'}}>
              <div style={{ border: '1px solid #d2d2d2' }}/>
            </div>
          </div>
          <Button
            fullWidth
            className="sign-in-button"
            color="white"
            onClick={() => this.submitFacebookLogin()}
            disabled={this.state.inAppBrowser}
          >
            <FaFacebookF color={'#4267B2'} style={{verticalAlign: 'middle', marginRight: 10}} />Sign in with FaceBook
          </Button>
          <Button
            fullWidth
            className="sign-in-button"
            color="white"
            onClick={() => this.submitGoogleLogin()}
            disabled={this.state.inAppBrowser}
          >
            <img src='/google-icon.svg' height="18px" width="18px" style={{verticalAlign: 'middle', marginRight: 10}}></img> Sign in with Google
          </Button>
          {this.state.inAppBrowser && (
            <Alert severity="warning" sx={{ mt: 2 }}>
              Facebook and Google Sign-in are not supported on this in-app browser.
              If you wish to sign in with Facebook or Google, please open this page on your device browser
              E.g. Safari/Chrome.
            </Alert>
          )}
        </div>
        <div>
          <p style={{textAlign: 'center', fontFamily: 'Poppins', marginTop: 5}}>
            Don't have an account?
          </p>
          <Button
            className="sign-in-button"
            size="sm"
            color="black"
            onClick={() => this.props.createAccount()}
            style={{margin: 'auto', display: 'block', marginBottom: 30}}
          >
              Create a Styler account
          </Button>
        </div>
      </div>
    );
  }
};

export default SignInForm;